import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/archetypes.webp'
import seeker from '../assets/images/archetypes/MJ/new/seeker.webp'

import caregiver from '../assets/images/archetypes/MJ/new/caregiver.webp'
import creator from '../assets/images/archetypes/MJ/new/creator.webp'
import rebel2 from '../assets/images/archetypes/MJ/new/rebel2.webp'
import fool from '../assets/images/archetypes/MJ/new/fool.webp'
import child from '../assets/images/archetypes/MJ/new/child.webp'
import king from '../assets/images/archetypes/MJ/new/king.webp'
import lover from '../assets/images/archetypes/MJ/new/lover.webp'
import magician from '../assets/images/archetypes/MJ/new/magician.webp'
import orphan from '../assets/images/archetypes/MJ/new/orphan.webp'
import senex from '../assets/images/archetypes/MJ/new/senex.webp'
import warrior from '../assets/images/archetypes/MJ/new/warrior.webp'

import Graph from '../components/graph'


import { createMedia } from "@artsy/fresnel"
import ImgMediaCard from '../components/card'
import MoreA from '../components/morea'
import More from '../components/more'


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})





class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Archetypes";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="All in one place"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h1>Archetypes</h1>

<p>
            Whereas the contents of the personal unconscious are acquired during the individual’s lifetime, the contents of the collective unconscious are invariably archetypes that were present from the beginning. </p>
          <p>(more below the archetypes list)</p>


            <ul className="actions">
                  <li>
                    <a href="/seeker" className="button">
                    <img src={seeker} className="kruh"/>
                      Seeker
                    </a>
                  </li>
                  <li>
                  <a href="/innocent" className="button">
                  <img src={child} className="kruh"/>
                    Innocent
                  </a>
                  </li>
                  <li>
                  <a href="/orphan" className="button">
                  <img src={orphan} className="kruh"/>
                    Orphan
                  </a>
                  </li>
                  <li>
                  <a href="/fool" className="button">
                  <img src={fool} className="kruh"/>
                    Fool (Jester)
                  </a>
                  </li>
                  <li>
                  <a href="/sage" className="button">
                  <img src={senex} className="kruh"/>
                    Sage (Senex)
                  </a>
                  </li>
                  <li>
                  <a href="/king" className="button">
                  <img src={king} className="kruh"/>
                    King
                  </a>
                  </li>
                  <li>
                  <a href="/creator" className="button">
                  <img src={creator} className="kruh"/>
                    Creator
                  </a>
                  </li>
                  <li>
                  <a href="/destroyer" className="button">
                  <img src={rebel2} className="kruh"/>
                    Rebel (Destroyer)
                  </a>
                  </li>
                  <li>
                  <a href="/magician" className="button">
                  <img src={magician} className="kruh"/>
                    Magician
                  </a>
                  </li>
                  <li>
                  <a href="/caregiver" className="button">
                  <img src={caregiver} className="kruh"/>
                    Caregiver
                  </a>
                  </li>
                  <li>
                  <a href="/lover" className="button">
                  <img src={lover} className="kruh"/>
                    Lover
                  </a>
                  </li>
                  <li>
                  <a href="/warrior" className="button">
                  <img src={warrior} className="kruh"/>
                    Warrior
                  </a>
                  </li>
                </ul>


                            <h2>If you want to know more about archetypes and Jungian psychology...</h2>




                            <More arch={"archetype"}/>
                            
                              <p/>
                              <h1>More on archetypes</h1>
                              <p>
                              Let us suppose that a certain individual shows no inclination whatever to recognize his projections. The projection-making factor then has a free hand and can realize its object—if it has one—or bring about some other situation characteristic of its power. As we know, it is not the conscious subject but the unconscious which does the projecting. Hence one meets with projections, one does not make them. The effect of projection is to isolate the subject from his environment, since instead of a real relation to it there is now only an illusory one. Projections change the world into the replica of one’s own unknown face.
                              </p><p>
                              The unconscious as we know can never be “done with” once and for all. It is, in fact, one of the most important tasks of psychic hygiene to pay continual attention to the symptomatology of unconscious contents and processes, for the good reason that the conscious mind is always in danger of becoming one-sided, of keeping to well-worn paths and getting stuck in blind alleys. The complementary and compensating function of the unconscious ensures that these dangers, which are especially great in neurosis, can in some measure be avoided. It is only under ideal conditions, when life is still simple and unconscious enough to follow the serpentine path of instinct without hesitation or misgiving, that the compensation works with entire success. The more civilized, the more conscious and complicated a man is, the less he is able to follow his instincts. His complicated living conditions and the influence of his environment are so strong that they drown the quiet voice of nature. Opinions, beliefs, theories, and collective tendencies appear in its stead and back up all the aberrations of the conscious mind. Deliberate attention should then be given to the unconscious so that the compensation can set to work. Hence it is especially important to picture the archetypes of the unconscious not as a rushing phantasmagoria of fugitive images but as constant, autonomous factors, which indeed they are. <i>(Aion, Carl Jung)</i>
                              </p>

          </section>


        </div>
      </Layout>
    )
  }
}

export default Result
